












































































































import { contactsStore, tasksStore, teamsStore } from '@/store'
import { formatText } from '@/utils/Common'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { SavedTaskItem } from './types'

@Component({
  components: {
    Actions: () => import('./Actions.vue'),
    Editor: () => import('./Editor.vue'),
    IconEllipsis: () => import('@/components/UI/icons/IconEllipsis.vue'),
    IconEllipsisVDouble:
      () => import('@/components/UI/icons/IconEllipsisVDouble.vue'),
    Loading: () => import('./Loading.vue'),
    Subtask: () => import('./Subtask.vue'),
    Unsaved: () => import('./Unsaved.vue'),
  },
})
export default class Item extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: SavedTaskItem
  @Prop({ type: Number }) readonly counter?: number
  @Prop({ type: Boolean }) readonly isEditing?: boolean
  @Prop({ type: Boolean }) readonly isLoading?: boolean
  @Prop({ type: Boolean }) readonly canEdit?: boolean
  @Prop({ type: Object }) readonly unsavedItem?: SavedTaskItem

  @Getter private readonly profile?: TADA.Contact

  get canCreateTask (): boolean {
    return this.profile?.canCreateTask ?? false
  }

  get assigneeDisplayName (): string | undefined {
    const jid = this.item.subtask?.assignee
    if (jid) return contactsStore.getters.contactDisplayName(jid)
  }

  get formattedText (): string {
    return formatText({ value: this.item.text })
  }

  get initialText (): string {
    return this.unsavedItem?.text ?? this.item.text
  }

  get showUnsavedItem (): boolean {
    return !this.isEditing && !!this.unsavedItem
  }

  openTask (ev: MouseEvent): string | undefined {
    const subtask = this.item.subtask
    if (!subtask || ev.ctrlKey || ev.shiftKey || ev.metaKey) return
    ev.preventDefault()
    this.$router.push({
      name: 'Chat',
      params: {
        jid: subtask.jid,
        teamId: teamsStore.getters.currentTeam.uid,
      },
    })
  }

  get taskStatusTitle (): string | undefined {
    if (
      this.item.subtask?.taskStatus &&
      tasksStore.getters.hasExtraStatuses
    ) return tasksStore.getters.statusTitle(this.item.subtask.taskStatus)
  }

  get subtaskHref (): string | undefined {
    if (!this.item.subtask) return
    return `/${teamsStore.state.currentTeamId}/chats/${this.item.subtask.jid}`
  }
}
